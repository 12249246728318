import React, { useEffect, useCallback, useMemo } from 'react'
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { Box, Button, CardActions, CardContent, Typography } from '@mui/material'
import { TrainingParticipationData } from '@/features/my/types'
import { TrainingDialogs } from '../TrainingDialogs/TrainingDialogs'
import { CardGridStack } from '@/common/components/CardGridStack'
import { Compliance } from '@/icons/Compliance'
import { DataProtection } from '@/icons/DataProtection'
import FontFaceLoader from '@/theme/CustomFont'
import { useTranslation } from 'react-i18next'
import { isPast, parseISO } from 'date-fns'
import cardListStyles, { textStyles } from './CardListStyles'
import { useHistory, useLocation } from '@/hooks'

type CardListProps = {
  list: TrainingParticipationData[]
}

export const CardList: React.FC<CardListProps> = React.memo(({ list }) => {
  const { t } = useTranslation('general')
  const [open, setOpen] = React.useState(false)
  const [trainingSelected, setTrainingSelected] = React.useState<TrainingParticipationData | undefined>()
  const history = useHistory()
  const location = useLocation()

  const isOverdue = useCallback((endDate: string | undefined) => endDate && isPast(parseISO(endDate)), [])

  const handleTraining = useCallback(
    (training: TrainingParticipationData) => {
      setTrainingSelected(training)
      setOpen(true)
    },
    [history]
  )

  const handleClose = useCallback(() => {
    setOpen(false)
    setTrainingSelected(undefined)
    history.push('/my/trainings')
  }, [history])

  useEffect(() => {
    const trainingCode = location.pathname.split('/').slice(-1)[0]
    if (trainingCode) {
      const training = list.find((t) => t.courseId === trainingCode)
      if (training) {
        setTrainingSelected(training)
        setOpen(true)
      } else {
        setOpen(false)
      }
    }
  }, [location.pathname, list])

  useEffect(() => {
    if (trainingSelected) {
      history.replace(`/my/trainings/${trainingSelected.courseId}`)
    }
  }, [trainingSelected, history])

  const renderTrainingCard = useCallback(
    (training: TrainingParticipationData) => (
      <Box key={training.courseId}>
        <CardContent>
          <CardActions disableSpacing>
            {training?.status === t('training_overdue') && <Compliance />}
            {training?.status === t('training_completed') && <DataProtection />}
            {!training?.status && <Compliance />}
            {isOverdue(training?.trainingEndDate) ? (
              <Button sx={cardListStyles.overdueButton} startIcon={<AccessAlarmIcon />}>
                {t('training_overdue')}
              </Button>
            ) : training.status === 'completed' ? (
              <Button sx={cardListStyles.completedButton} startIcon={<CheckCircleOutlineIcon />}>
                {t('training_completed')}
              </Button>
            ) : (
              <Typography sx={cardListStyles.buttonTypography}>
                {t('training_dueDate')} {training?.trainingEndDate}
              </Typography>
            )}
          </CardActions>
          <Box sx={{ m: 1 }}>
            <Typography sx={textStyles.title}>{training?.title}</Typography>
            <Typography sx={textStyles.duration}>{training?.duration} minutes</Typography>
            <Typography sx={textStyles.description}>{training?.description}</Typography>
          </Box>
        </CardContent>
        <CardActions sx={cardListStyles.cardActions}>
          <Button onClick={() => handleTraining(training)} sx={cardListStyles.viewButton}>
            {training?.button}
          </Button>
          {training?.lastActive && (
            <Typography sx={cardListStyles.repeatTypography} color="text.secondary">
              {t('training_repeat')} {training?.repeat}
            </Typography>
          )}
        </CardActions>
      </Box>
    ),
    [t, isOverdue, handleTraining]
  )

  const memoizedCards = useMemo(() => list?.map(renderTrainingCard), [list, renderTrainingCard])

  return (
    <Box sx={cardListStyles.container}>
      <FontFaceLoader />
      <CardGridStack xs={12} sm={12} items={memoizedCards} />
      <TrainingDialogs open={open} handleClose={handleClose} training={trainingSelected} />
    </Box>
  )
})

CardList.displayName = 'CardList'
